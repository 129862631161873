@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap);
.flex{
    display: flex;
    align-items: center;
    justify-content: center;
}
.login_page{
    width: 100%;
    height: 100%;
}
.login_card{
    width: 70%;
    height: 70%;
}

.login_image img{
    width: 85%;
    margin-left: 30px;
}
.login_form div{
    flex-direction: column;
    width: 100%;
 
  margin-top: -20px;
}
.login_form form{
     flex-direction: column;
     width: 100%;
}
.login_form h2{
    font-family: 'Poppins', sans-serif;
   margin-bottom: 30px;
    font-size: 1.5rem;
}
.login_form input{
    width: 63%;
    height: 40px;
    border-radius: 30px;
    border:1.5px solid rgb(177, 177, 177);
    font-family: 'Poppins', sans-serif;
    margin: 10px 0;
    font-family: 'Poppins', sans-serif;
    padding: 20px;
}
.login_form button{
    width: 64%;
    height: 45px;
    border: none;
    border-radius: 30px;
    color: #fff;
    background-color: #5569ff;
    margin: 10px 0;
    font-family: 'Poppins', sans-serif;
}
.login_form button:hover{
    background-color: #5160d4;
}
.invalidmsg{
    font-family: 'Poppins', sans-serif;
    color:red;
    font-size: 0.8rem;
}
